import { HydrateOption } from '@core/enums';
import { Device, until } from '@helpers/media';
import { isSSR } from '@helpers/ssr';
import { withMotion } from '@hoc/withMotion';
import withWidget from '@hoc/withWidget';
import useMedia from '@hooks/useMedia';
import SiteWide from '@stories/Components/Global/SiteWide/SiteWide';
import DotPagination from '@stories/Components/Misc/DotPagination/DotPagination';
import Breadcrumbs from '@stories/Widgets/Meta/Breadcrumbs/Breadcrumbs.widget';
import { MotionConfig } from 'framer-motion';
import React from 'react';
import { HomepageCarouselStyles as S } from './HomepageCarousel.styles';
import HomepageCarouselItem from './HomepageCarouselItem/HomepageCarouselItem';
const HomepageCarousel = ({ items, breadcrumb }) => {
    const [activeIndex, setActiveIndex] = React.useState(0);
    const isMobile = useMedia([until(Device.Tablet)], [true], false);
    return (React.createElement(MotionConfig, { transition: { duration: 0.45, ease: 'easeOut' } },
        React.createElement(S.Container, null,
            breadcrumb && (React.createElement(S.BreadcrumbWrapper, null,
                React.createElement(Breadcrumbs, { items: breadcrumb }))),
            React.createElement(S.ItemList, null,
                !isMobile &&
                    !isSSR() &&
                    items.map((item, index) => (React.createElement(S.Item, { key: item.id, "aria-current": activeIndex === index },
                        React.createElement(HomepageCarouselItem, { ...item, active: activeIndex === index, index: index })))),
                isMobile || (isSSR() && items.length > 0) ? (React.createElement(S.Item, { key: items[0].id, "aria-current": true },
                    React.createElement(HomepageCarouselItem, { ...items[0], active: true, index: 0 }))) : null),
            items.length > 1 && !isMobile && !isSSR() && (React.createElement(S.PaginationWrapper, null,
                React.createElement(SiteWide, null,
                    React.createElement(DotPagination, { count: items.length, value: activeIndex, onChange: (index) => setActiveIndex(index) })))))));
};
export default withWidget(withMotion(HomepageCarousel), 'HomepageCarousel', {
    hydrate: HydrateOption.Always,
});
