import brand from '@helpers/brand';
import { Device, from } from '@helpers/media';
import styled from 'styled-components';
const Container = styled.nav `
  --color: ${brand.white};
  --gap: 4px;
  --height: 16px;
  --opacityInactive: 0.65;
  --opacityActive: 1;
  --tapSize: 48px;
  --width: 16px;

  --tapPadding: calc((var(--tapSize) - var(--width)) / 2);

  display: flex;
  flex-direction: row;
  gap: var(--gap);
  place-content: center;
  place-items: center;
  user-select: none;

  @media ${from(Device.Tablet)} {
    --gap: 8px;
    --height: 16px;
    --width: 16px;
  }
`;
const Item = styled.button `
  position: relative;
  display: flex;
  background: none;
  border: 0;
  color: var(--color);
  margin: 0;
  opacity: var(--opacityInactive);
  padding: var(--tapPadding);
  place-content: center;
  place-items: center;
  transition: opacity 0.15s ease-out, transform 0.15s ease-out;
  will-change: opacity, transform;

  &::before {
    content: '';
    display: block;
    background: currentColor;
    border: 0;
    color: currentColor;
    height: var(--height);
    width: var(--width);
  }

  /* Enable focus styles for Safari */
  &:focus {
    background: none;
    outline: none;

    &::before {
      outline: auto;
      outline-color: currentColor;
      outline-offset: 5px;
      outline-width: thin;
    }
  }

  /* Enable hover styles for Safari */
  &:hover {
    background: none;
    opacity: var(--opacityActive);
    transform: scale(1.2);
  }

  /* Disable focus styles if focus visible is supported */
  &:not(:focus-visible):focus {
    outline: none;

    &::before {
      outline: none;
    }
  }

  /* Reapply focus styles for focus-visible browsers */
  &:focus-visible {
    outline: none;

    &::before {
      outline: auto;
      outline-color: currentColor;
      outline-offset: 5px;
      outline-width: thin;
    }
  }

  /* Apply hover styles for focus-visible browsers */
  &:not(:focus-visible):hover {
    opacity: var(--opacityActive);
    transform: scale(1.2);
  }

  /* Apply active styles */
  &[aria-current='true'] {
    opacity: var(--opacityActive);
    transform: scale(1.2);
  }
`;
const DotPaginationStyles = {
    Container,
    Item,
};
export default DotPaginationStyles;
