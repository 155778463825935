import brand from '@helpers/brand';
import { Device, from, until } from '@helpers/media';
import SiteWideStyles from '@stories/Components/Global/SiteWide/SiteWide.styles';
import DotPaginationStyles from '@stories/Components/Misc/DotPagination/DotPagination.styles';
import { BreadcrumbsStyles } from '@stories/Widgets/Meta/Breadcrumbs/Breadcrumbs.styles';
import { m } from 'framer-motion';
import styled from 'styled-components';
const Container = styled(m.section) `
  position: relative;
`;
const BreadcrumbWrapper = styled.div `
  position: absolute;
  width: 100%;
  z-index: 2;

  ${BreadcrumbsStyles.Inner} li a, ${BreadcrumbsStyles.Inner} li:after {
    color: ${brand.white};
  }
`;
const ItemList = styled.ul `
  position: relative;
  display: grid;
  z-index: 0;
`;
const Item = styled.li `
  grid-area: 1 / 1;
  pointer-events: none;

  &[aria-current='true'] {
    pointer-events: all;
  }
`;
const PaginationWrapper = styled.div `
  pointer-events: none;
  z-index: 1;

  ${DotPaginationStyles.Container} {
    pointer-events: all;
  }

  @media ${until(Device.TabletLarge)} {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;

    ${SiteWideStyles.Container} {
      display: flex;
      justify-content: center;
    }

    ${DotPaginationStyles.Container} {
      flex-direction: row;
    }
  }

  @media ${from(Device.TabletLarge)} {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;

    ${SiteWideStyles.Container} {
      display: flex;
      justify-content: flex-start;
    }

    ${DotPaginationStyles.Container} {
      flex-direction: column;
    }
  }
`;
export const HomepageCarouselStyles = {
    BreadcrumbWrapper,
    Container,
    ItemList,
    Item,
    PaginationWrapper,
};
